import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom'
import { useCookies } from 'react-cookie';
import { TabContent, TabPane, Nav, NavItem, Alert, Card, CardHeader, CardBody, CardFooter, Row, Col, Modal, ModalBody } from 'reactstrap';
import { toast } from 'react-toastify';
import { Input, Container } from 'reactstrap';
import PageSpinner from 'components/PageSpinner';
import { FaArrowLeft, FaCheck } from 'react-icons/fa';
import { toJpeg } from 'html-to-image';
import Carousel from 'react-grid-carousel';
import axios from 'axios';
import softworks from '../assets/img/logo-cms/softworks.png';
import icon from '../assets/img/icon.png';
import irishTimes from '../assets/img/logo-cms/irish-times-logo.png';
import elis from '../assets/img/company-logos/elis.png';
import crannog from '../assets/img/company-logos/crannog.png';
import winesDirect from '../assets/img/company-logos/wines-direct.JPG';
import esb from '../assets/img/company-logos/esb.jpg';
import larnrod from '../assets/img/company-logos/larnrod.JPG';
import kirby from '../assets/img/company-logos/kirby.jpg';
import lmc from '../assets/img/company-logos/lmc.png';
import mitie from '../assets/img/company-logos/mitie.png';
import bam from '../assets/img/company-logos/bam.jpg';
import lotusWorks from '../assets/img/company-logos/lotus-works.jpg';
import faithfulGould from '../assets/img/company-logos/faithful-gould.JPG';
import fastway from '../assets/img/company-logos/fastway.png';
import leckey from '../assets/img/company-logos/leckey.png';
import mannok from '../assets/img/company-logos/mannok.png';
import tirlan from '../assets/img/company-logos/tirlan.jpg';
import gasNetworks from '../assets/img/company-logos/gas-networks.png';
import sensori from '../assets/img/company-logos/sensori.jpg';
import abbvie from '../assets/img/company-logos/abbvie.png';
import allview from '../assets/img/company-logos/allview.jpg';
import keltech from '../assets/img/company-logos/keltech.jpg';
import bridgewater from '../assets/img/company-logos/bridgewater.jpg';
import guildhall from '../assets/img/company-logos/guildhall.jpg';
import ruralWifi from '../assets/img/company-logos/rural-wifi.png';
import workhuman from '../assets/img/company-logos/workhuman.png';
import donoghue from '../assets/img/company-logos/donoghue.jpg';
import dpd from '../assets/img/company-logos/dpd.png';
import george from '../assets/img/company-logos/george.jpg';
import mias from '../assets/img/company-logos/mias.png';
import centric from '../assets/img/company-logos/centric.jpg';
import comfort from '../assets/img/company-logos/comfort.png';
import uisce from '../assets/img/company-logos/uisce.png';
import viking from '../assets/img/company-logos/viking.jpg';
import abbott from '../assets/img/company-logos/abbott.jpeg';
import griffithResidence from '../assets/img/company-logos/griffith-residence.png';
import griffithFaculty from '../assets/img/company-logos/griffith-faculty.jpg';
import west from '../assets/img/company-logos/west.jpg';
import anpost from '../assets/img/company-logos/anpost.png';
import eurofins from '../assets/img/company-logos/eurofins.PNG';
import ventac from '../assets/img/company-logos/ventac.png';
import breedon from '../assets/img/company-logos/breedon.png';
import primark from '../assets/img/company-logos/primark.jpg';
import kerrygold from '../assets/img/company-logos/kerrygold.png';
import southCoast from '../assets/img/company-logos/south-coast.jpg';
import skibbereen from '../assets/img/company-logos/skibbereen.jpg';
import salesforce from '../assets/img/company-logos/salesforce.png';
import atu from '../assets/img/company-logos/atu.jpg';
import cheshire from '../assets/img/company-logos/cheshire.jpg';
import iss from '../assets/img/company-logos/iss.png';
import byrne from '../assets/img/company-logos/byrne.png';
import asgard from '../assets/img/company-logos/asgard.png';

const initialFormData = Object.freeze({
  name: '',
  description: '',
  awards: '',
  logo: '',
  schedule_1: '',
  schedule_2: '',
  schedule_3: '',
  schedule_4: '',
  schedule_5: '',
  website_url: '',
  finalists_url: '',
  judges_url: '',
  about_url: '',
  support_email: '',
  support_phone: '',
  host: '',
  host_description: '',
  start: '',
  location: '',
  dacast_url: '',
  vimeo_url: '',
  entry_guide_url: '',
  status: '',
});

const initialSeatViewData = Object.freeze({
  id: '',
});

const initialAttendeeData = Object.freeze({
  id: '',
  name: '',
  contact_number: '',
  company_name: '',
  company_position: ''
});

const initialVenueData = Object.freeze({
  venue_id: '',
  stage_length: '',
  stage_width: '',
  table_length: '',
  table_width: '',
  table_type: '',
  number_of_tables: '',
  total_seats_per_table: '',
  venue_name: '',
});

const initialResetFloorPlan = Object.freeze({
  floor_plan: '',
});

const initialTableData = Object.freeze({
  id: '',
  name: '',
});

const initialMoveAttendeeData = Object.freeze({
  attendee_ids_from: '',
  attendee_ids_to: '',
  table_number_from: '',
  table_number_to: '',
  user_id: '',
});

const AdminVenueSeatingPage = () => {
  let history = useHistory();
  const ref = useRef(null); // ref => { current: null }
  const stageRef = useRef(); // ref => { current: null }
  const [formData, setFormData] = useState(initialFormData);
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  const award_id = query.get('award_id');
  const [award, setAward] = useState();

  const [cookies, setCookies, removeCookies] = useCookies(['currentUser', 'userRole', 'isLoggedIn', 'awardStatus', 'isWelcome', 'isFinalist', 'token', 'userDetails']);
  const currentUser = cookies.currentUser;
  const token = cookies.token;
  const isLoggedIn = cookies.isLoggedIn;
  let updatedAt = '';

  const isWarning = 'warning';
  const isSuccess = 'success';
  const isError = 'error';
  const toastCSS = {
    position: 'top-right',
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  }
  const [attendeesListLoading, setAttendeesListLoading] = useState(false);
  const [attendees, setAttendees] = useState([]);
  const [seatingArrangementLoading, setSeatingArrangementLoading] = useState(false);
  const [reloadCount, setReloadCount] = useState(1);
  const [attendee, setAttendee] = useState(initialAttendeeData);
  const [venueData, setVenueData] = useState(initialVenueData);
  const [tables, setTables] = useState([]);
  const [tablesPlaceholder, setTablesPlacholder] = useState([]);
  const [numberOfSeats, setNumberOfSeats] = useState(0);
  const [tablesLoading, setTablesLoading] = useState(true);
  const [moveAttendeeData, setMoveAttendeeData] = useState(initialMoveAttendeeData);
  const [attendeeIdsFrom, setAttendeeIdsFrom] = useState([]);
  const [attendeeIdsTo, setAttendeeIdsTo] = useState([]);
  const [tableData, setTableData] = useState(initialTableData);
  const [table, setTable] = useState(initialTableData);
  const [tableModalShow, setTableModalShow] = useState(false);
  const [tableLoading, setTableLoading] = useState(true);
  const [tableId, setTableId] = useState("");
 
  const [tableTo, setTableTo] = useState([]);
  const [tableFrom, setTableFrom] = useState([]);
  const [tableToSeats, setTableToSeats] = useState(0);
  const [tableFromSeats, setTableFromSeats] = useState(0);
  const [tableFromAttendees, setTableFromAttendees] = useState(0);
  const [selectAtleast, setSelectAtleast] = useState(true);
  const [moveAttendeeShow, setMoveAttendeeShow] = useState(false);
  const [tableIds, setTableIds] = useState([]);
  const [seatingArrangementModal, setSeatingArrangementModal] = useState(false);
  const [seatingImage, setSeatingImage] = useState();
  const [imageLoading, setImageLoading] = useState();

  const toggleTableModalShow = () => {
    setTableModalShow(!tableModalShow);
  }
  

  const getAward = async () => {
    return await axios.get(process.env.REACT_APP_API_ENDPOINT + 'award/' + award_id + '?user_id=' + currentUser + '&token=' + token);
  }

  const getCheckSeat = async () => {
    return await axios.get(process.env.REACT_APP_API_ENDPOINT + 'award/' + award_id + '/seat/check?user_id=' + currentUser + '&token=' + token);
  }

  const getTablesNew = async () => {
    return await axios.get(process.env.REACT_APP_API_ENDPOINT + 'award/' + award_id + '/venue/table?user_id=' + currentUser + '&token=' + token);
  }

  const getAttendees = async () => {
    return await axios.get(process.env.REACT_APP_API_ENDPOINT + 'award/' + award_id + '/attendee?award_id=' + award_id + '&user_id=' + currentUser + '&token=' + token);
  }

  const toastAlert = ({ type, message }) => {
    if (type === 'error') {
      toast.error(message, toastCSS);
    } else if (type === 'warning') {
      toast.warning(message, toastCSS);
    } else {
      toast.success(message, toastCSS);
    }
  }

  const requestSuccess = () => {
    const message = 'Success!';
    toastAlert({ type: isSuccess, message: message });
  }

  const catchError = () => {
    const message = 'An error occured. Please try again or contact the administrator.';
    toastAlert({ type: isError, message: message });
  }

  const catchWarning = () => {
    const message = 'Something went wrong!';
    toastAlert({ type: isWarning, message: message });
  }

  const handleChangeAttendee = (e) => {
    const { name, value } = e.target;
    setAttendee({
      ...attendee,
      [name]: value,
    });
  }

  const submitSeatingImageCallback = useCallback(async () => {
    setImageLoading(() => true);
    await toJpeg(stageRef.current, { quality: 1.0 })
      .then(async (dataUrl) => {
        setSeatingImage(() => dataUrl);
        await submitSeatingImage(dataUrl);

      })
      .catch(() => {
        catchError();
      });

  }, [stageRef]);

  const toggleSeatingArrangement = e => {
    setSeatingArrangementModal(!seatingArrangementModal);
  }

  const retrieveHistory = async () => {
    const response = fetch(process.env.REACT_APP_API_ENDPOINT + 'award/'+award_id+'/seat/history?user_id=' + currentUser +'&token='+token, {
    // const response = fetch(process.env.REACT_APP_API_ENDPOINT + 'award/'+award_id+'/seating/history?award_id=' + award_id + '&user_id=' + currentUser +'&token='+token, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, *same-origin, omit
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        'Content-Type': 'application/json',
        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
        "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'client', // no-referrer, *client
    })
      .then((response) => {
        return response.json();
      })
      .then((events) => {
        return events.data;
      });
    return response;
  };

  const retrieveAttendeesSearch = async (e) => {
    const response = fetch(process.env.REACT_APP_API_ENDPOINT + 'award/'+award_id+'/attendee?award_id='+award_id+'&user_id='+currentUser+'&token='+token+'&table_numbers='+tableIds+'&query='+e , {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, *same-origin, omit
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        'Content-Type': 'application/json',
        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
        "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'client', // no-referrer, *client
    })
      .then((response) => {
        return response.json();
      })
      .then((events) => {
        return events.data;
      });
    return response;
  };

  const retrieveTablesSearch = async (e) => {
    const response = fetch(process.env.REACT_APP_API_ENDPOINT + 'award/'+award_id+'/seat/plan?award_id='+award_id+'&user_id='+currentUser+'&token='+token+'&table_numbers='+tableIds+'&query='+e , {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, *same-origin, omit
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        'Content-Type': 'application/json',
        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
        "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'client', // no-referrer, *client
    })
      .then((response) => {
        return response.json();
      })
      .then((events) => {
        return events.data;
      });
    return response;
  };

  const retrieveTables = async () => {
    const response = fetch(process.env.REACT_APP_API_ENDPOINT + 'award/'+award_id+'/venue/table?award_id='+award_id+'&user_id='+currentUser+'&token='+token , {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, *same-origin, omit
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        'Content-Type': 'application/json',
        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
        "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'client', // no-referrer, *client
    })
      .then((response) => {
        return response.json();
      })
      .then((events) => {
        return events.data;
      });
    return response;
  };

  async function submitSeatingImage(e) {
    const response = await fetch(process.env.REACT_APP_API_ENDPOINT + 'award/'+award_id+'?user_id=' + currentUser + '&token=' + token, {
      method: 'PUT', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, *same-origin, omit
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        'Content-Type': 'application/json',
        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
        "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'client', // no-referrer, *client
      body: JSON.stringify({
        user_id: currentUser,
        award_id: award_id,
        judge_allocation: e,
      }), // body data type must match "Content-Type" header
    })
    const json = await response.json();
    if (json.status == "Success") {
      setReloadCount(reloadCount + 1);
      setAttendeeIdsFrom([]);
      setAttendeeIdsTo([]);
      setTableTo([]);
      setTableFrom([]);
    }
  }

  const addTableNumber = (id) => {
    if (!tableIds.includes(id)) {
      setTableIds(tableIds => [...tableIds, id]);
    }
  }

  const removeTableNumber = (id) => {
    let filteredArray = tableIds.filter(item => item !== id);
    setTableIds(filteredArray);
  }

  const addAttendeeTo = (id) => {
    if (attendeeIdsTo.includes(id)) {
    } else {
      setAttendeeIdsTo(attendeeIdsTo => [...attendeeIdsTo, id]);
      setMoveAttendeeData({
        ...moveAttendeeData,
        user_id: currentUser,
        attendee_ids_to: attendeeIdsTo,
      });
      var attendee_ids_to = [...attendeeIdsTo, id];
      var seatsNeeded = ((tableToSeats - tableTo.length) - attendeeIdsFrom.length) + attendee_ids_to.length;
      if (seatsNeeded >= 0) {
        setSelectAtleast(false);
      } else {
        setSelectAtleast(true);
      }
    }
  }

  const removeAttendeeTo = (id) => {
    let filteredArray = attendeeIdsTo.filter(item => item !== id);
    setAttendeeIdsTo(filteredArray);
    setMoveAttendeeData({
      ...moveAttendeeData,
      user_id: currentUser,
      attendee_ids_to: filteredArray,
    });
    var seatsNeeded = ((tableToSeats - tableTo.length) - attendeeIdsFrom.length) + filteredArray.length;
    if (seatsNeeded >= 0) {
      setSelectAtleast(false);
    } else {
      setSelectAtleast(true);
    }
  }

  const retrieveTableTo = async (e) => {
    const response = fetch(process.env.REACT_APP_API_ENDPOINT + 'venue/table/'+e+'?award_id=' + award_id + '&user_id=' + currentUser +'&token='+token, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, *same-origin, omit
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        'Content-Type': 'application/json',
        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
        "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'client', // no-referrer, *client
    })
      .then((response) => {
        return response.json();
      })
      .then((events) => {
        return events.data;
      });
    return response;
  };

  const retrieveTable = async (e) => {
    const response = fetch(process.env.REACT_APP_API_ENDPOINT + 'venue/table/'+e+'?award_id=' + award_id + '&user_id=' + currentUser +'&token='+token, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, *same-origin, omit
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        'Content-Type': 'application/json',
        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
        "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'client', // no-referrer, *client
    })
      .then((response) => {
        return response.json();
      })
      .then((events) => {
        return events.data;
      });
    return response;
  };

  const retrieveTableFrom = async (e) => {
    const response = fetch(process.env.REACT_APP_API_ENDPOINT + 'venue/table/'+e+'?award_id=' + award_id + '&user_id=' + currentUser +'&token='+token, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, *same-origin, omit
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        'Content-Type': 'application/json',
        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
        "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'client', // no-referrer, *client
    })
      .then((response) => {
        return response.json();
      })
      .then((events) => {
        return events.data;
      });
    return response;
  };

  const handleTableFrom = (e) => {
    var table_id_from = e;
    setAttendeeIdsFrom([]);
    setMoveAttendeeData({
      ...moveAttendeeData,
      table_number_from: table_id_from,
    });

    const getTableFrom = async (table_id_from) => {
      const selectedTableFrom = await retrieveTableFrom(table_id_from);
      if(selectedTableFrom) {
        setTableFrom(selectedTableFrom.attendees);
        setTableFromSeats(selectedTableFrom.no_of_seats);
        setTableFromAttendees(selectedTableFrom.attendees.length);
      }
    };
    getTableFrom(table_id_from);

  }

  const handleChangeTable = (e) => {
    setTableData({
      ...tableData,
      [e.target.name]: e.target.value,
    });
  }

  const handleTableTo = (e) => {
    setAttendeeIdsTo([]);
    var table_id_to = e.target.value;

    if (table_id_to && table_id_to != "") {
      setMoveAttendeeData({
        ...moveAttendeeData,
        table_number_to: e.target.value,
      });
  
      const getTableTo = async (table_id_to) => {
        const selectedTableTo = await retrieveTableTo(table_id_to);
        if(selectedTableTo) {
          setTableTo(selectedTableTo.attendees);
          setMoveAttendeeShow(true);
          setTableToSeats(selectedTableTo.no_of_seats);
        }
      };
  
      getTableTo(table_id_to);
    }
  }

  const handleTable = (e) => {
    var table_id = e;
    setTableData({
      ...tableData,
      id: table_id,
    });
    setTableId(table_id);
    toggleTableModalShow();
    const getTable = async (table_id) => {
      const selectedTable = await retrieveTable(table_id);
      if(selectedTable) {
        setTable(selectedTable);
        setTableData({
          ...tableData,
          name: selectedTable.name,
        });
        setTableLoading(false);
      }
    };
    getTable(table_id);
  }

  const getTables = async () => {
    const selectedTables = await retrieveTables();
    if (selectedTables) {
      const removedTableIds = [ 67, 68 ];
      const tables = selectedTables.filter(table => table.category !== "other" && !removedTableIds.includes(table.id)).map(x => x);
      const placeholder = tables.length % 8;
      let hiddenColumns = [];

      let length = tables.length / 32;

      if (length % 1 > 0) {
        length++;
      }

      const sortedTable = tables.sort((a, b) => parseInt(a.name) - parseInt(b.name)).filter(table => table.category !== "other").map(x => x);

      let result = [];
      for (let i = length - length % 1; i > 0; i--) {
        result.push(sortedTable.splice(0, 32));
      }
      setTables(result);
      for (let i = 0; i < 8 - placeholder; i++)
        hiddenColumns = [...hiddenColumns, ''];

      setTablesPlacholder(() => hiddenColumns);
      // setTablesPlan(selectedTables.tables);
      setSeatingArrangementLoading(false);
      setTablesLoading(false);
    }
  }

  const getCompanyLogos = (companyName) => {
    const companyLogo = [
      {
        company_name: 'Abbott Nutrition Cootehill',
        logo: abbott
      },
      {
        company_name: 'AbbVie',
        logo: abbvie
      },
      {
        company_name: 'An Post',
        logo: anpost
      },
      {
        company_name: 'BAM Facilities Management',
        logo: bam
      },
      {
        company_name: 'Breedon Group',
        logo: breedon
      },
      {
        company_name: 'Bridgewater Construction',
        logo: bridgewater
      },
      {
        company_name: 'Centric Health',
        logo: centric
      },
      {
        company_name: 'Comfort Keepers Homecare',
        logo: comfort
      },
      {
        company_name: 'Crannog Roofing',
        logo: crannog
      },
      {
        company_name: 'AllView Healthcare',
        logo: allview
      },
      {
        company_name: 'DPD Ireland',
        logo: dpd
      },
      {
        company_name: 'Elis Pest Control',
        logo: elis
      },
      {
        company_name: 'ESB',
        logo: esb
      },
      {
        company_name: 'Eurofins BioPharmaceutical Product Testing',
        logo: eurofins
      },
      {
        company_name: 'Faithful+Gould',
        logo: faithfulGould
      },
      {
        company_name: 'Fastway',
        logo: fastway
      },
      {
        company_name: 'Gas Networks Ireland',
        logo: gasNetworks
      },
      {
        company_name: 'George Best Belfast City Airport',
        logo: george
      },
      {
        company_name: 'Griffith College Faculty of Law',
        logo: griffithFaculty
      },
      {
        company_name: 'Griffith Halls of Residence',
        logo: griffithResidence
      },
      {
        company_name: 'Guildhall - Derry City and Strabane District Council',
        logo: guildhall
      },
      {
        company_name: 'Iarnród Éireann Irish Rail',
        logo: larnrod
      },
      {
        company_name: 'Leckey',
        logo: leckey
      },
      {
        company_name: 'Keltech',
        logo: keltech
      },
      {
        company_name: 'Kerrygold Park, Ornua',
        logo: kerrygold
      },
      {
        company_name: 'Kirby Group Engineering',
        logo: kirby
      },
      {
        company_name: 'LMC FM',
        logo: lmc
      },
      {
        company_name: 'LotusWorks',
        logo: lotusWorks
      },
      {
        company_name: 'Mannok',
        logo: mannok
      },
      {
        company_name: 'MIAS Pharma',
        logo: mias
      },
      {
        company_name: 'Mitie',
        logo: mitie
      },
      {
        company_name: 'O\'Donoghue Ring Collection',
        logo: donoghue
      },
      {
        company_name: 'Primark',
        logo: primark
      },
      {
        company_name: 'Rural WiFi',
        logo: ruralWifi
      },
      {
        company_name: 'South Coast Logistics',
        logo: southCoast
      },
      {
        company_name: 'The Skibbereen Food Company',
        logo: skibbereen
      },
      {
        company_name: 'Tirlán',
        logo: tirlan
      },
      {
        company_name: 'Uisce Éireann',
        logo: uisce
      },
      {
        company_name: 'Ventac',
        logo: ventac
      },
      {
        company_name: 'Viking UK/IE',
        logo: viking
      },
      {
        company_name: 'West Pharmaceutical Services',
        logo: west
      },
      {
        company_name: 'Wines Direct',
        logo: winesDirect
      },
      {
        company_name: 'Workhuman',
        logo: workhuman
      },
      {
        company_name: 'Salesforce',
        logo: salesforce
      },
      {
        company_name: 'Atlantic Technological University (ATU) Sligo',
        logo: atu
      },
      {
        company_name: 'Cheshire Ireland',
        logo: cheshire
      },
      {
        company_name: 'ISS Facility Services Ireland',
        logo: iss
      },
      {
        company_name: 'Sensori Facilities Management',
        logo: sensori
      },
      {
        company_name: 'Cushman & Wakefield',
        logo: sensori
      }
    ];

    if (companyName) {
      const image = companyLogo.filter(x => {
        if (x.company_name == companyName) {
          return x;
        }
      }).map(x => x);

      if(image.length < 1) {
        return '';
      } else {
        return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: '0.5vw'}} src={image[0].logo}/>);
      }
    } else {
      return '';
    }
  }

  useEffect(() => {
    if (!(isLoggedIn !== 'true' && window.location.pathname.includes('admin'))) {
      getAward().then(response => {
        const selectedAward = response.data.data;
        if (selectedAward) {
          setAward(selectedAward);

          setFormData({
            ...formData,
            name: selectedAward.name,
            logo: selectedAward.logo,
            website_url: selectedAward.website_url,
            description: selectedAward.description,
            support_email: selectedAward.support_email,
            support_phone: selectedAward.support_phone,
            event: selectedAward.event,
            entry_guide_url: selectedAward.event.entry_guide_url,
            status: selectedAward.status
          });
          setVenueData({
            ...venueData,
            venue_name: selectedAward.venue.name,
          });
          setTablesLoading(false);
        } else {
          catchWarning();
        }
      }).catch(() => {
        catchError();
      });
    }
  }, []);

  useEffect(() => {
    getTables();
  }, [reloadCount])

  useEffect(() => {
    if (isLoggedIn !== 'true' && window.location.pathname.includes('admin')) {
      window.location.href = "http://app.businessriver.com/";
    } else {
      const interval = setInterval( () => {
        getCheckSeat().then(response => {
          const result = response.data.seat_last_updated;
          if (result != updatedAt) {
            updatedAt = result;
            if (updatedAt) {
              getTables();
            }
          }
        }).catch(() => catchError());
      }, 8000);

      return () => clearInterval(interval);
    }
  }, []);

  const handleSearch = (e) => {
    const getTables = async (e) => {
      const selectedTables = await retrieveTablesSearch(e);
      if (selectedTables) {
        setTables(selectedTables.tables);
        setSeatingArrangementLoading(false);
        if (selectedTables.total_seats_per_table) {
          setNumberOfSeats(selectedTables.total_seats_per_table);
        }
        setTablesLoading(false);
      }
    };
    getTables(e);

    const getAttendees = async (e) => {
      const selectedAttendees = await retrieveAttendeesSearch(e);
      if (selectedAttendees) {
        setAttendeesListLoading(false);
        setAttendees(selectedAttendees);
      };
    };

    getAttendees(e);
  }

  return (
    <>
      {/*<section id="bis_award_tab">*/}
        <Container className={window.location.pathname.includes('venue/plan') ? 'h-100 mt-2' : 'mt-2'} fluid>
          {tablesLoading ?
            <>
              <PageSpinner />
            </>
            :
            <Row>
              <Col lg="12">
                <Card className={window.location.pathname.includes('venue/plan') ? 'border-0' : null}>
                  {!window.location.pathname.includes('venue/plan') ?
                    <div className='card-body py-2 px-4' style={{borderBottom: '1px solid #f2e7e7'}}>
                      {/*{window.location.pathname.includes('admin') ?*/}
                      {/*  <div className='text-right floor-plan-back'>*/}
                      {/*    <div className="bis-tooltip" onClick={() => history.goBack()}>*/}
                      {/*      /!* BACK *!/*/}
                      {/*      <FaArrowLeft className="text-black edit-icon" />*/}
                      {/*      /!* <span className="bis-tooltiptext">BACK</span> *!/*/}
                      {/*    </div>*/}
                      {/*  </div>*/}
                      {/*  :*/}
                      {/*  null*/}
                      {/*}*/}
                      <p className='floor-plan-font mb-0 d-flex justify-content-between align-items-center'>
                        <img src={formData.logo} style={{height: '50px'}}/>
                        <div>
                          <span style={{fontSize: '20px', fontWeight: '700'}}>Seating Plan</span>
                          <div className="floor-plan-font-divider"></div>
                          {venueData.venue_name && venueData.venue_name != "" ?
                            <p className="floor-plan-subfont mb-0" style={{fontSize: '10px', marginTop: '6px'}}>{award.venue.name}</p>
                            :
                            null
                          }
                        </div>
                      </p>
                    </div>
                    :
                    null
                  }
                  <CardBody className='py-1'>
                      {tables.length > 0 ?
                        <>
                          {/* Descending */}
                          <Carousel cols={1} rows={1} containerStyle={{maxWidth: '100%', marginBottom: '0.5rem'}} showDots={false} hideArrow={true}  loop dotColorActive='#1ec2f3' autoplay={10000}>
                            {tables.map((x, tableIndex) => (
                              <Carousel.Item>
                                <Row>
                                  {x.map(({ id, image, name, no_of_seats, company_name, attendees }, index) => (
                                    <>
                                      <Col className="mb-1" key={"attendee-table-"+index}>
                                        <Card className="h-100 border-0">
                                          <CardHeader className='p-1 d-flex align-items-center justify-content-between border-bottom-0'>
                                            {/*<Row>*/}
                                            {/*  <Col lg="12">*/}
                                            <h5 className="modal-title text-uppercase text-left mb-0" style={{fontSize: '0.5vw'}}>Table {name}</h5>
                                            <span className='d-flex align-items-center'>
                                              {/*<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: '0.5vw'}} src={softworks}/>*/}
                                              {/*<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: '0.5vw'}} src={icon}/>*/}
                                              {/*<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: '0.5vw'}} src={irishTimes}/>*/}
                                            </span>
                                            {/*  </Col>*/}
                                            {/*</Row>*/}
                                          </CardHeader>
                                          <CardBody className='py-0 px-1 border-top-0'>
                                            {attendees.length > 0 ?
                                              <>
                                                {attendees.map((user, user_index) => {
                                                  return (
                                                    <>
                                                      <div className="mb-0 d-flex justify-content-between">
                                                        <div
                                                          className='d-flex align-items-center mr-1'
                                                        >
                                                          <span className='attendee-box taken mr-1' style={{height: '0.5vw', width: '0.5vw'}}></span>
                                                          <span
                                                            style={{
                                                              fontSize: '0.5vw',
                                                              textOverflow: 'ellipsis',
                                                              whiteSpace: 'nowrap',
                                                              overflow: 'hidden',
                                                              width: '5vw'
                                                            }}>
                                                            {user.first_name && user.last_name ?
                                                              user.first_name + ' ' + user.last_name
                                                              : user.first_name ?
                                                                user.first_name
                                                                : user.last_name ?
                                                                  user.last_name
                                                                  :
                                                                  'Reserved'
                                                            }
                                                          </span>
                                                        </div>
                                                        {/*<div className='text-center mr-1'*/}
                                                        {/*     style={{*/}
                                                        {/*       fontSize: '0.5vw',*/}
                                                        {/*       textOverflow: 'ellipsis',*/}
                                                        {/*       whiteSpace: 'nowrap',*/}
                                                        {/*       overflow: 'hidden',*/}
                                                        {/*       width: '7vw'*/}
                                                        {/*     }}>*/}
                                                        {/*  {user.company_name}*/}
                                                        {/*</div>*/}
                                                        <div className='d-flex align-items-center justify-content-end' style={{width: '2vw'}}>
                                                          {getCompanyLogos(user.company_name)}
                                                        </div>
                                                      </div>

                                                    </>
                                                  )
                                                })}
                                                {(function (rows, i, len) {
                                                  while (++i <= len) {
                                                    rows.push(
                                                      <div className="mb-0 d-flex align-items-center" style={{fontSize: '0.5vw'}}>
                                                        <div
                                                          className='d-flex align-items-center'
                                                        >
                                                          <span className='attendee-box available mr-1' style={{height: '0.5vw', width: '0.5vw'}}></span>
                                                          <span style={{
                                                            fontSize: '0.5vw',
                                                            textOverflow: 'ellipsis',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            width: '5vw'
                                                          }}>Available</span>
                                                        </div>
                                                        <div className='text-right'
                                                             style={{
                                                               fontSize: '0.5vw',
                                                               textOverflow: 'ellipsis',
                                                               whiteSpace: 'nowrap',
                                                               overflow: 'hidden',
                                                               width: '2vw'
                                                             }}>
                                                        </div>
                                                      </div>
                                                    )
                                                  }
                                                  return rows;
                                                })([], attendees.length, no_of_seats)}
                                              </>
                                              :
                                              <>
                                                {(function (rows, i, len) {
                                                  while (++i <= len) {
                                                    rows.push(
                                                      <div className="mb-0 d-flex align-items-center" style={{fontSize: '0.5vw'}}>
                                                        <div
                                                          className='d-flex align-items-center'
                                                        >
                                                          <span className='attendee-box available mr-1' style={{height: '0.5vw', width: '0.5vw'}}></span>
                                                          <span style={{
                                                            fontSize: '0.5vw',
                                                            textOverflow: 'ellipsis',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            width: '5vw'
                                                          }}>Available</span>
                                                        </div>
                                                        <div className='text-right'
                                                             style={{
                                                               fontSize: '0.5vw',
                                                               textOverflow: 'ellipsis',
                                                               whiteSpace: 'nowrap',
                                                               overflow: 'hidden',
                                                               width: '2vw'
                                                             }}>
                                                        </div>
                                                      </div>
                                                    )
                                                  }
                                                  return rows;
                                                })([], 0, no_of_seats)}
                                              </>
                                            }
                                          </CardBody>
                                        </Card>
                                      </Col>
                                      {tables.length - 1 === tableIndex && x.length - 1 === index ?
                                        <>
                                          {tablesPlaceholder.map(() => (
                                            <Col>
                                              <Card className='border-0'>
                                                <CardHeader className='border-0 py-0 px-1'>
                                                  <h5 className="modal-title d-inline text-uppercase text-left" style={{fontSize: '0.5vw'}}> </h5>
                                                </CardHeader>
                                                <CardBody className='border-0 py-0 px-1'>
                                                  <div className="mb-0 d-flex align-items-center" style={{fontSize: '0.5vw'}}>
                                                    <div
                                                      className='d-flex align-items-center'
                                                    >
                                                      {/*<span className='attendee-box available mr-1' style={{height: '0.5vw', width: '0.5vw'}}></span>*/}
                                                      <span style={{
                                                        fontSize: '0.5vw',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        width: '5vw'
                                                      }}> </span>
                                                    </div>
                                                    <div className='text-right'
                                                         style={{
                                                           fontSize: '0.5vw',
                                                           textOverflow: 'ellipsis',
                                                           whiteSpace: 'nowrap',
                                                           overflow: 'hidden',
                                                           width: '2  vw'
                                                         }}>
                                                    </div>
                                                  </div>
                                                </CardBody>
                                              </Card>
                                            </Col>
                                          ))}
                                        </>
                                        :
                                        null
                                      }
                                    </>
                                  ))}
                                </Row>
                              </Carousel.Item>
                            ))}
                          </Carousel>
                          {/*{tables.sort((a, b) => parseInt(a.name) - parseInt(b.name)).filter(table => table.category !== "other").map(({ id, image, name, no_of_seats, company_name, attendees }, index) => (*/}
                          {/*  <Col lg="4" className="mb-3" key={"attendee-table-"+index}>*/}
                          {/*    <Card className="h-100">*/}
                          {/*      <CardHeader>*/}
                          {/*        <Row>*/}
                          {/*          <Col lg="12">*/}
                          {/*            <h5 className="modal-title d-inline text-uppercase text-left">Table {name}</h5>*/}
                          {/*          </Col>*/}
                          {/*        </Row>*/}
                          {/*      </CardHeader>*/}
                          {/*      <CardBody>*/}
                          {/*        {attendees.length > 0 ?*/}
                          {/*          <>*/}
                          {/*            {attendees.map((user, user_index) => {*/}
                          {/*              return (*/}
                          {/*                <>*/}
                          {/*                  <div className="mb-2 d-flex justify-content-between">*/}
                          {/*                    <div className='d-flex align-items-center'>*/}
                          {/*                      <span className='attendee-box taken mr-1'></span>*/}
                          {/*                      {user.first_name && user.last_name ?*/}
                          {/*                        <span> {user.first_name} {user.last_name}</span>*/}
                          {/*                        : user.first_name ?*/}
                          {/*                          <span> {user.first_name}</span>*/}
                          {/*                          : user.last_name ?*/}
                          {/*                            <span> {user.last_name}</span>*/}
                          {/*                            :*/}
                          {/*                            <span> Reserved</span>*/}
                          {/*                      }*/}
                          {/*                    </div>*/}
                          {/*                    <div className='text-right'>*/}
                          {/*                      {user.company_name}*/}
                          {/*                    </div>*/}
                          {/*                  </div>*/}

                          {/*                </>*/}
                          {/*              )*/}
                          {/*            })}*/}
                          {/*            {(function (rows, i, len) {*/}
                          {/*              while (++i <= len) {*/}
                          {/*                rows.push(*/}
                          {/*                  <div className="mb-2">*/}
                          {/*                    <span className='attendee-box available mr-1'></span>*/}
                          {/*                    <span>Available</span>*/}
                          {/*                  </div>*/}
                          {/*                )*/}
                          {/*              }*/}
                          {/*              return rows;*/}
                          {/*            })([], attendees.length, no_of_seats)}*/}
                          {/*          </>*/}
                          {/*          :*/}
                          {/*          <>*/}
                          {/*            {(function (rows, i, len) {*/}
                          {/*              while (++i <= len) {*/}
                          {/*                rows.push(*/}
                          {/*                  <div className="attendee-container available mb-2">*/}
                          {/*                    <span>Available</span>*/}
                          {/*                  </div>*/}
                          {/*                )*/}
                          {/*              }*/}
                          {/*              return rows;*/}
                          {/*            })([], 0, no_of_seats)}*/}
                          {/*          </>*/}
                          {/*        }*/}
                          {/*      </CardBody>*/}
                          {/*    </Card>*/}
                          {/*  </Col>*/}
                          {/*))}*/}
                        </>
                        :
                        <div lg={12}>
                          <p className="mb-0 text-center">No records found</p>
                        </div>
                      }
                    {/*  </CardBody>*/}
                    {/*</Card>*/}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          }
        </Container>
        
      {/*</section>*/}
    </>
  )
}

export default AdminVenueSeatingPage;
