import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { Button, Form, FormGroup, Row, Col, Container } from "reactstrap";
import BISlogoblue from 'assets/img/bis/logo2.png';
import BISLogoWhite from '../assets/img/logo/Business-River-Logo-White.png';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import CustomToast from '../utils/CustomToast';
import PageSpinner from '../components/PageSpinner';

const initialBookingData = Object.freeze({
  id: '',
  award: {
    id: 0
  },
  code: '',
  digital_attendees: 0,
  digital_attendees_total: 0,
  live_attendees: 0,
  performance_benchmark: 0,
  status: '',
  total_amount: 0,
  subtotal_amount: 0,
  winner_package: 0,
  irish_times_coverage: 0,
  global_broadcast: 0,
  all_features: 0,
  live_attendees_tables: 0,
  live_attendees_individuals: 0,
  po_number: "",
  line_items: [],
  additional_10_seats: 0,
});

const BookingsConfirmationLogin = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const [cookies, setCookie, removeCookie] = useCookies(['currentUser', 'isLoggedIn', 'award_id', 'section', 'failsafe_award_id', 'userRole']);
  const [bookingData, setBookingData] = useState(initialBookingData);
  const [loading, setLoading] = useState(true);
  const [formStatus, setFormStatus] = useState('standby');

  let query = useQuery();
  const code = query.get('code');
  const method = query.get('method');
  const po = query.get('po');

  const postRegister = async (data) => {
    return await axios.post(process.env.REACT_APP_API_ENDPOINT + 'register', data);
  };

  const getBooking = async (data) => {
    return await axios.get(process.env.REACT_APP_API_ENDPOINT + 'booking/' + code + '/details/v2', data);
  };


  const signupSubmit = (e) => {
    const currentUrl = window.location.href;

    const data = {
      ...e,
      url: currentUrl,
      booking_code: code
    };

    setFormStatus("login");
    postRegister(data).then(response => {
      const { status, data, errors } = response.data;

      if (status === "Success") {

        setCookie('userDetails', JSON.stringify(data), { path: '/' });
        setCookie('awardSettings', data.user.award_settings, { path: '/' });
        setCookie('currentUser', JSON.stringify(data.id), { path: '/' });
        setCookie('isLoggedIn', true, { path: '/' });
        setCookie('userRole', data.user.role, { path: '/' });
        setCookie('judge', data.user.judge, { path: '/' });
        setCookie('judge_id', data.user.judge_id, { path: '/' });
        setCookie('coordinator_id', data.user.coordinator_id, { path: '/' });
        setCookie('award_id', bookingData.award.id, { path: '/' });
        setCookie('token', data.token, { path: '/' });
        setCookie('cookie_award_id', bookingData.award.id, { path: '/' });
        setCookie('failsafe_award_id', bookingData.award.id, { path: '/' });

        let redirectUrl = `/stripe?code=${code}&award_code=${bookingData.award.award_type.code.replace('&', '%26')}${bookingData.first_name === 'Jeno (Test)' ? '&test=1' : bookingData.first_name === 'BR 1Eur Test' ? '&oneeuro=1' : ''}`;

        if (method === 'bt')
          redirectUrl = `/bank/transfer?award_id=${bookingData.award.id}&booking_code=${code}&po_number=${bookingData.po_number}&po=${po ? po : ''}`;

        CustomToast.success('Login successfully!', () => window.location.href = redirectUrl);

      } else {
        if (errors.email) {
          CustomToast.error(errors.email[0])
        } if (errors.password) {
          CustomToast.error(errors.password[0]);
        } else {
          CustomToast.error(errors[0]);
        }
        setFormStatus("standby");

      }
    }).catch();
  }

  useEffect(() => {
    getBooking().then(response => {
      const { errors, data } = response.data;

      if (!errors) {
        setBookingData(data);
        setLoading(false);
      } else {
        CustomToast.defaultWarning();
      }
    }).catch(() => CustomToast.defaultError());
  }, []);

  return (
    <>
      <ToastContainer style={{ width: "370px" }} />

      <div className="VEPbackground d-flex align-items-center pt-5 pb-5">
        <Container className="d-flex justify-content-center">
          <Row
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'white'
            }}
            className="login-registration-row">
            <Col lg={12} className="VEPcardbackground mobile-only">
              <div className='bis-logo-white mt-5'>
                <img src={BISLogoWhite} alt="Business River" />
              </div>
            </Col>
            <Col lg={12} className="pt-3 pb-4 px-4" style={{ backgroundColor: '#fff' }}>
              <div id="bis-form">
                <Form onSubmit={handleSubmit(signupSubmit)} className="bis_signup_form container">
                  <div className='text-center mt-3 mb-3'>
                    <img
                      src={BISlogoblue}
                      className="rounded"
                      style={{ height: 60 }}
                      alt="logo"
                    />
                  </div>
                  <p className="text-center form_login mb-0">MEMBER REGISTRATION</p>

                  <h3>LOGIN INFORMATION</h3>
                  <Row className="card_bg_white">
                    <Col lg={12}>
                      <FormGroup>
                        <p className='small mb-1'>Email Address<span className="asterisk_red">*</span> </p>
                        <input {...register("email", { required: true })} type="email" maxLength={80} className={`form-control ${errors.email ? "form-invalid mb-0" : "mb-3"}`} />
                        {errors.email ? <p role="alert" className="invalid-feedback">Email field is required</p> : null}
                      </FormGroup>
                      <Row>
                        <Col lg={6}>
                          <FormGroup>
                            <p className='small mb-1'>Password<span className="asterisk_red">*</span></p>
                            <input {...register("password", { required: true })} type="password" maxLength={255} className={`form-control ${errors.password ? "form-invalid mb-0" : "mb-0"}`} />
                            {errors.email ? <p role="alert" className="invalid-feedback mb-0">Password field is required</p> : null}
                          </FormGroup>
                        </Col>
                        <Col lg={6}>
                          <FormGroup>
                            <p className='small mb-1'>Confirm Password<span className="asterisk_red">*</span></p>
                            <input {...register("password_confirmation", { required: true })} type="password" maxLength={255} className={`form-control ${errors.password_confirmation ? "form-invalid mb-0" : "mb-0"}`} />
                            {errors.password_confirmation ? <p role="alert" className="invalid-feedback  mb-0">Password confirmation field is required</p> : null}
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <h3>PERSONAL INFORMATION</h3>
                  <Row className="card_bg_white">
                    <Col lg={6}>
                      <p className='small mb-1'>First Name<span className="asterisk_red">*</span></p>
                      <input {...register("first_name", { required: true })} type="text" maxLength={40} className={`form-control ${errors.first_name ? "form-invalid mb-0" : "mb-0"}`} />
                      {errors.first_name ? <p role="alert" className="invalid-feedback mb-0">First Name field is required</p> : null}
                    </Col>
                    <Col lg={6}>
                      <p className='small mb-1'>Last Name<span className="asterisk_red">*</span></p>
                      <input {...register("last_name", { required: true })} type="text" maxLength={40} className={`form-control ${errors.last_name ? "form-invalid mb-0" : "mb-0"}`} />
                      {errors.last_name ? <p role="alert" className="invalid-feedback mb-0">Last Name field is required</p> : null}
                    </Col>
                  </Row>
                  <h3>COMPANY DETAILS</h3>
                  <Row className="card_bg_white">
                    <Col lg={12}>
                      <p className='small mb-1'>Company<span className="asterisk_red">*</span></p>
                      <input {...register("company_name", { required: true })} type="text" maxlength={150} className={`form-control ${errors.company_name ? "form-invalid mb-0" : "mb-3"}`} />
                      {errors.company_name ? <p role="alert" className="invalid-feedback">Company field is required</p> : null}
                    </Col>
                    <Col lg={6}>
                      <p className='small mb-1'>Position/Job Title<span className="asterisk_red">*</span></p>
                      <input {...register("company_position", { required: true })} type="text" maxlength={150} className={`form-control ${errors.company_position ? "form-invalid mb-0" : "mb-3"}`} />
                      {errors.company_position ? <p role="alert" className="invalid-feedback">Company Position field is required</p> : null}
                    </Col>
                    <Col lg={6}>
                      <p className='small mb-1'>Industry<span className="asterisk_red">*</span></p>
                      <select {...register("company_industry", { required: true })} maxLength={50} className={`form-control ${errors.company_industry ? "form-invalid mb-0" : "mb-3"}`}>
                        <option value="Accounting">Accounting</option>
                        <option value="Airlines/Aviation">Airlines/Aviation</option>
                        <option value="Alternative Dispute Resolution">Alternative Dispute Resolution</option>
                        <option value="Alternative Medicine">Alternative Medicine</option>
                        <option value="Animation">Animation</option>
                        <option value="Apparel/Fashion">Apparel/Fashion</option>
                        <option value="Architecture/Planning">Architecture/Planning</option>
                        <option value="Arts/Crafts">Arts/Crafts</option>
                        <option value="Automotive">Automotive</option>
                        <option value="Aviation/Aerospace">Aviation/Aerospace</option>
                        <option value="Banking/Mortgage">Banking/Mortgage</option>
                        <option value="Biotechnology/Greentech">Biotechnology/Greentech</option>
                        <option value="Broadcast Media">Broadcast Media</option>
                        <option value="Building Materials">Building Materials</option>
                        <option value="Business Supplies/Equipment">Business Supplies/Equipment</option>
                        <option value="Capital Markets/Hedge Fund/Private Equity">Capital Markets/Hedge Fund/Private Equity</option>
                        <option value="Chemicals">Chemicals</option>
                        <option value="Civic/Social Organization">Civic/Social Organization</option>
                        <option value="Civil Engineering">Civil Engineering</option>
                        <option value="Commercial Real Estate">Commercial Real Estate</option>
                        <option value="Computer Games">Computer Games</option>
                        <option value="Computer Hardware">Computer Hardware</option>
                        <option value="Computer Networking">Computer Networking</option>
                        <option value="Computer Software/Engineering">Computer Software/Engineering</option>
                        <option value="Computer/Network Security">Computer/Network Security</option>
                        <option value="Construction">Construction</option>
                        <option value="Consumer Electronics">Consumer Electronics</option>
                        <option value="Consumer Goods">Consumer Goods</option>
                        <option value="Consumer Services">Consumer Services</option>
                        <option value="Cosmetics">Cosmetics</option>
                        <option value="Dairy">Dairy</option>
                        <option value="Defense/Space">Defense/Space</option>
                        <option value="Design">Design</option>
                        <option value="E-Learning">E-Learning</option>
                        <option value="Education Management">Education Management</option>
                        <option value="Electrical/Electronic Manufacturing">Electrical/Electronic Manufacturing</option>
                        <option value="Entertainment/Movie Production">Entertainment/Movie Production</option>
                        <option value="Environmental Services">Environmental Services</option>
                        <option value="Events Services">Events Services</option>
                        <option value="Executive Office">Executive Office</option>
                        <option value="Facilities Services">Facilities Services</option>
                        <option value="Farming">Farming</option>
                        <option value="Financial Services">Financial Services</option>
                        <option value="Fine Art">Fine Art</option>
                        <option value="Fishery">Fishery</option>
                        <option value="Food Production">Food Production</option>
                        <option value="Food/Beverages">Food/Beverages</option>
                        <option value="Fundraising">Fundraising</option>
                        <option value="Furniture">Furniture</option>
                        <option value="Gambling/Casinos">Gambling/Casinos</option>
                        <option value="Glass/Ceramics/Concrete">Glass/Ceramics/Concrete</option>
                        <option value="Government Administration">Government Administration</option>
                        <option value="Government Relations">Government Relations</option>
                        <option value="Graphic Design/Web Design">Graphic Design/Web Design</option>
                        <option value="Health/Fitness">Health/Fitness</option>
                        <option value="Higher Education/Acadamia">Higher Education/Acadamia</option>
                        <option value="Hospital/Health Care">Hospital/Health Care</option>
                        <option value="Hospitality">Hospitality</option>
                        <option value="Human Resources/HR">Human Resources/HR</option>
                        <option value="Import/Export">Import/Export</option>
                        <option value="Individual/Family Services">Individual/Family Services</option>
                        <option value="Industrial Automation">Industrial Automation</option>
                        <option value="Information Services">Information Services</option>
                        <option value="Information Technology/IT">Information Technology/IT</option>
                        <option value="Insurance">Insurance</option>
                        <option value="International Affairs">International Affairs</option>
                        <option value="International Trade/Development">International Trade/Development</option>
                        <option value="Internet">Internet</option>
                        <option value="Investment Banking/Venture">Investment Banking/Venture</option>
                        <option value="Investment Management/Hedge Fund/Private Equity">Investment Management/Hedge Fund/Private Equity</option>
                        <option value="Judiciary">Judiciary</option>
                        <option value="Law Enforcement">Law Enforcement</option>
                        <option value="Law Practice/Law Firms">Law Practice/Law Firms</option>
                        <option value="Legal Services">Legal Services</option>
                        <option value="Legislative Office">Legislative Office</option>
                        <option value="Leisure/Travel">Leisure/Travel</option>
                        <option value="Library">Library</option>
                        <option value="Logistics/Procurement">Logistics/Procurement</option>
                        <option value="Luxury Goods/Jewelry">Luxury Goods/Jewelry</option>
                        <option value="Machinery">Machinery</option>
                        <option value="Management Consulting">Management Consulting</option>
                        <option value="Maritime">Maritime</option>
                        <option value="Market Research">Market Research</option>
                        <option value="Marketing/Advertising/Sales">Marketing/Advertising/Sales</option>
                        <option value="Mechanical or Industrial Engineering">Mechanical or Industrial Engineering</option>
                        <option value="Media Production">Media Production</option>
                        <option value="Medical Equipment">Medical Equipment</option>
                        <option value="Medical Practice">Medical Practice</option>
                        <option value="Mental Health Care">Mental Health Care</option>
                        <option value="Military Industry">Military Industry</option>
                        <option value="Mining/Metals">Mining/Metals</option>
                        <option value="Motion Pictures/Film">Motion Pictures/Film</option>
                        <option value="Museums/Institutions">Museums/Institutions</option>
                        <option value="Music">Music</option>
                        <option value="Nanotechnology">Nanotechnology</option>
                        <option value="Newspapers/Journalism">Newspapers/Journalism</option>
                        <option value="Non-Profit/Volunteering">Non-Profit/Volunteering</option>
                        <option value="Oil/Energy/Solar/Greentech">Oil/Energy/Solar/Greentech</option>
                        <option value="Online Publishing">Online Publishing</option>
                        <option value="Other Industry">Other Industry</option>
                        <option value="Outsourcing/Offshoring">Outsourcing/Offshoring</option>
                        <option value="Package/Freight Delivery">Package/Freight Delivery</option>
                        <option value="Packaging/Containers">Packaging/Containers</option>
                        <option value="Paper/Forest Products">Paper/Forest Products</option>
                        <option value="Performing Arts">Performing Arts</option>
                        <option value="Pharmaceuticals">Pharmaceuticals</option>
                        <option value="Philanthropy">Philanthropy</option>
                        <option value="Photography">Photography</option>
                        <option value="Plastics">Plastics</option>
                        <option value="Political Organization">Political Organization</option>
                        <option value="Primary/Secondary Education">Primary/Secondary Education</option>
                        <option value="Printing">Printing</option>
                        <option value="Professional Training">Professional Training</option>
                        <option value="Program Development">Program Development</option>
                        <option value="Public Relations/PR">Public Relations/PR</option>
                        <option value="Public Safety">Public Safety</option>
                        <option value="Publishing Industry">Publishing Industry</option>
                        <option value="Railroad Manufacture">Railroad Manufacture</option>
                        <option value="Ranching">Ranching</option>
                        <option value="Real Estate/Mortgage">Real Estate/Mortgage</option>
                        <option value="Recreational Facilities/Services">Recreational Facilities/Services</option>
                        <option value="Religious Institutions">Religious Institutions</option>
                        <option value="Renewables/Environment">Renewables/Environment</option>
                        <option value="Research Industry">Research Industry</option>
                        <option value="Restaurants">Restaurants</option>
                        <option value="Retail Industry">Retail Industry</option>
                        <option value="Security/Investigations">Security/Investigations</option>
                        <option value="Semiconductors">Semiconductors</option>
                        <option value="Shipbuilding">Shipbuilding</option>
                        <option value="Sporting Goods">Sporting Goods</option>
                        <option value="Sports">Sports</option>
                        <option value="Staffing/Recruiting">Staffing/Recruiting</option>
                        <option value="Supermarkets">Supermarkets</option>
                        <option value="Technology">Technology</option>
                        <option value="Telecommunications">Telecommunications</option>
                        <option value="Textiles">Textiles</option>
                        <option value="Think Tanks">Think Tanks</option>
                        <option value="Tobacco">Tobacco</option>
                        <option value="Translation/Localization">Translation/Localization</option>
                        <option value="Transportation">Transportation</option>
                        <option value="Utilities">Utilities</option>
                        <option value="Venture Capital/VC">Venture Capital/VC</option>
                        <option value="Veterinary">Veterinary</option>
                        <option value="Warehousing">Warehousing</option>
                        <option value="Wholesale">Wholesale</option>
                        <option value="Wine/Spirits">Wine/Spirits</option>
                        <option value="Wireless">Wireless</option>
                        <option value="Writing/Editing">Writing/Editing</option>
                      </select>
                      {errors.company_industry ? <p role="alert" className="invalid-feedback">Industry field is required</p> : null}
                    </Col>
                    <Col lg={12}>
                      <p className='small mb-1'>Company Phone Number<span className="asterisk_red">*</span></p>
                      {/* <Input type="tel" name="company_phone" onChange={handleChange} required/> */}
                      <input {...register("company_phone", { required: true })} type="tel" maxLength={150} className={`form-control ${errors.company_phone ? "form-invalid mb-0" : "mb-3"}`} />
                      {errors.company_phone ? <p role="alert" className="invalid-feedback">Company Phone Number field is required</p> : null}
                    </Col>
                  </Row>
                  {formStatus != "standby" ?
                    <>
                      <Button
                        size="lg"
                        className="border-0 mt-4"
                        block
                        type="submit"
                      >
                        Signing up...
                      </Button>
                    </>
                    :
                    <Button
                      size="lg"
                      type="submit"
                      className="border-0 mt-4"
                      block
                    >
                      Sign up
                    </Button>
                  }
                  <div className="text-center">
                    <span className="mr-2">Already have an account?</span>
                    <a href={`/award/booking/confirmation/login?code=${code}${method ? '&method=' + method : ''}${po ? '&po=' + po : ''}`} className="bis-link">
                      Login
                    </a>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default BookingsConfirmationLogin;
