import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom'
import { useCookies } from 'react-cookie';

const LogoutPage = (props) => {
    let history = useHistory();
    const [cookies, setCookie, removeCookie] = useCookies(['currentUser', 'isLoggedIn', 'userRole', 'judge_id', 'token','section','award_id']);
    const section = cookies.section;
    const award_id = cookies.award_id;

    useEffect(() => {
        removeCookie('token', { path: '/' });
        removeCookie('userDetails', { path: '/' });
        removeCookie('awardSettings', { path: '/' });
        removeCookie('isWelcome', { path: '/' });
        removeCookie('currentUser', { path: '/' });
        removeCookie('isLoggedIn', { path: '/' });
        removeCookie('userRole', { path: '/' });
        removeCookie('judge', { path: '/' });
        removeCookie('judge_id', { path: '/' });
        removeCookie('coordinator_id', { path: '/' });
        removeCookie('isAccept', { path: '/' });
        removeCookie('isProceed', { path: '/' });
        removeCookie('section', { path: '/' });
        removeCookie('cookie_section', { path: '/' });
        removeCookie('isSubmit', { path: '/' });
        setTimeout(function(){
            // if (section != "admin") {
            //     if (award_id) {
            //         history.push("/"+section+"/login?award_id="+award_id);
            //     } else {
            //         window.location.href = "http://app.businessriver.com/";
            //     }
            // } else {
            //     if (section) {
            //         history.push("/admin/login");
            //     } else {
            //         window.location.href = "http://app.businessriver.com/";
            //     }
            // }
            history.push("/admin/login");
        },500);
    }, []);

    return null;
}

export default LogoutPage;

