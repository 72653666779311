import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { Button } from "reactstrap";
import '../styles/stripe/stripe.css';
import bg from '../assets/img/bg/BG.png';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import CustomToast from '../utils/CustomToast';
import '../styles/stripe/stripe.css';
import PageSpinner from '../components/PageSpinner';

const initialBookingData = Object.freeze({
  id: '',
  award: {
    id: 0
  },
  code: '',
  digital_attendees: 0,
  digital_attendees_total: 0,
  live_attendees: 0,
  performance_benchmark: 0,
  status: '',
  total_amount: 0,
  subtotal_amount: 0,
  winner_package: 0,
  irish_times_coverage: 0,
  global_broadcast: 0,
  all_features: 0,
  live_attendees_tables: 0,
  live_attendees_individuals: 0,
  po_number: "",
  line_items: [],
  additional_10_seats: 0,
});

const BookingsConfirmationLogin = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const [cookies, setCookie, removeCookie] = useCookies(['currentUser', 'isLoggedIn', 'award_id', 'section', 'failsafe_award_id', 'userRole']);
  const [bookingData, setBookingData] = useState(initialBookingData);
  const [loading, setLoading] = useState(true);
  const [formStatus, setFormStatus] = useState('standby');

  let query = useQuery();
  const code = query.get('code');
  const method = query.get('method');
  const po = query.get('po');

  const postLogin = async (data) => {
    return await axios.post(process.env.REACT_APP_API_ENDPOINT + 'login', data);
  };

  const getBooking = async (data) => {
    return await axios.get(process.env.REACT_APP_API_ENDPOINT + 'booking/' + code + '/details/v2', data);
  };


  const loginSubmit = (e) => {
    const currentUrl = window.location.href;

    const data = {
      ...e,
      url: currentUrl,
      booking_code: code
    };

    setFormStatus("login");
    postLogin(data).then(response => {
      const { status, data, errors } = response.data;

      if (status === "Success") {

        setCookie('userDetails', JSON.stringify(data), { path: '/' });
        setCookie('awardSettings', data.user.award_settings, { path: '/' });
        setCookie('currentUser', JSON.stringify(data.id), { path: '/' });
        setCookie('isLoggedIn', true, { path: '/' });
        setCookie('userRole', data.user.role, { path: '/' });
        setCookie('judge', data.user.judge, { path: '/' });
        setCookie('judge_id', data.user.judge_id, { path: '/' });
        setCookie('coordinator_id', data.user.coordinator_id, { path: '/' });
        setCookie('award_id', bookingData.award.id, { path: '/' });
        setCookie('token', data.token, { path: '/' });
        setCookie('cookie_award_id', bookingData.award.id, { path: '/' });
        setCookie('failsafe_award_id', bookingData.award.id, { path: '/' });

        let redirectUrl = `/stripe?code=${code}&award_code=${bookingData.award.award_type.code.replace('&', '%26')}${bookingData.first_name === 'Jeno (Test)' ? '&test=1' : bookingData.first_name === 'BR 1Eur Test' ? '&oneeuro=1' : ''}`;

        if (method === 'bt')
          redirectUrl = `/bank/transfer?award_id=${bookingData.award.id}&booking_code=${code}&po_number=${bookingData.po_number}&po=${po ? po : ''}`;

        CustomToast.success('Login successfully!', () => window.location.href = redirectUrl);

      } else {
        if (errors.email) {
          CustomToast.error(errors.email[0])
        } if (errors.password) {
          CustomToast.error(errors.password[0]);
        } else {
          CustomToast.error(errors[0]);
        }
        setFormStatus("standby");

      }
    }).catch();
  }

  useEffect(() => {
    getBooking().then(response => {
      const { errors, data } = response.data;

      if (!errors) {
        setBookingData(data);
        setLoading(false);
      } else {
        CustomToast.defaultWarning();
      }
    }).catch(() => CustomToast.defaultError());
  }, []);

  return (
    <>
      <ToastContainer style={{ width: "370px" }} />
      <div id='hero-bussiness-river' style={{ backgroundImage: 'url(' + bg + ')' }}>
        <div className='DemoWrapper' style={{ maxWidth: "1060px" }} >
          <div className='Demo'>
            {loading ?
              <PageSpinner />
              :
              <form
                onSubmit={handleSubmit(loginSubmit)}
                className="bis_login_form"
              >
                <h1 className="booking-confirmation-h1 text-center mb-1">MEMBER LOGIN</h1>
                <div className="form-group d-flex justify-content-center mb-0">
                  <div className='w-100' style={{ maxWidth: '500px' }}>
                    <input
                      {...register("email", { required: "Email field is required" })}
                      placeholder="Email Address"
                      maxLength={80}
                      type="email"
                      className={`form-control ${errors.email ? "form-invalid mb-0" : "mb-1"}`}
                    />
                    {errors.email ? <p role="alert" className="invalid-feedback mb-1">Email field is required</p> : null}
                  </div>
                </div>
                <div className="form-group d-flex justify-content-center mb-0">
                  <div className='w-100' style={{ maxWidth: '500px' }}>
                    <input
                      {...register("password", { required: "Password field is required" })}
                      placeholder="Password"
                      maxLength={255}
                      type="password"
                      className={`form-control ${errors.password ? "form-invalid mb-0" : "mb-1"}`}
                    />
                    {errors.email ? <p role="alert" className="invalid-feedback mb-1">Password field is required</p> : null}
                  </div>
                </div>
                <div className="form-group d-flex justify-content-center mb-0">
                  {formStatus != "standby" ?
                    <Button
                      size="lg"
                      type="submit"
                      className="border-0 mt-1"
                      block>
                      Logging in...
                    </Button>
                    :
                    <Button
                      size="lg"
                      type="submit"
                      className="border-0 mt-1"
                      block
                    >
                      Login
                    </Button>
                  }
                </div>
                <div className='text-center'>
                  <a className='bis-link' href={'/forgotpassword?email='}>Forgot password?</a>
                </div>
                <div className="text-center mt-1">
                  <a href={`/award/booking/confirmation/register?code=${code}${method ? '&method=' + method : ''}${po ? '&po=' + po : ''}`} className="bis-link">
                    Register
                  </a>
                </div>
              </form>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default BookingsConfirmationLogin;
